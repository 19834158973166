import { Dispatch, HTMLAttributes, SetStateAction, SyntheticEvent } from 'react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import { styled, Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { SxProps } from '@mui/system';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface ISelectBoxProps {
  title?: string;
  placeholder?: string;
  data: ISelectOption[];
  filteredData: ISelectOption[];
  setData: Dispatch<SetStateAction<ISelectOption[]>>;
  isSelectAll?: boolean;
  dataTest?: string;
  sx?: SxProps<Theme>;
}

export interface ISelectOption {
  id: string;
  label: string;
  checked: boolean;
}

const AutocompleteRoot = styled(Autocomplete)({
  '&.Mui-focused .MuiAutocomplete-tag:not(:first-of-type)': {
    display: 'none',
  },
  '& .MuiAutocomplete-tag': {
    maxWidth: '130px',

    '.MuiSvgIcon-root': {
      display: 'none',
    },

    '.MuiChip-label': {
      padding: '0 5px',
    },
  },

  '&& .MuiFormControl-root .MuiInputBase-root .MuiInputBase-input': {
    padding: '10px 0 10px 10px',
  },
  '& span.MuiAutocomplete-tag': {
    padding: '6px 4px',
  },
  '& .MuiInputBase-formControl': {
    padding: '0 40px 0 2px',
  },
});

const StyledLi = styled('li')({ wordBreak: 'break-word' });

const CustomAutocomplete = (props: ISelectBoxProps) => {
  const { title, data, setData, filteredData, isSelectAll, placeholder, dataTest, sx } = props;
  const allSelected = data.length === filteredData.length;
  const filter = createFilterOptions();

  return (
    <AutocompleteRoot
      data-test={dataTest ?? ''}
      multiple
      limitTags={3}
      sx={sx}
      disableClearable
      options={data && data.length ? data : []}
      value={filteredData}
      disableCloseOnSelect
      onChange={(event: SyntheticEvent, selectedOptions) => {
        if ((selectedOptions as ISelectOption[]).find((option) => option.id === '-1')) {
          setData(!allSelected ? data : []);
        } else {
          setData(selectedOptions as ISelectOption[]);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        if (isSelectAll) {
          return [{ label: 'Select All', id: '-1' }, ...filtered];
        }

        return filtered;
      }}
      renderOption={(renderProps: HTMLAttributes<HTMLLIElement>, option: any) => {
        const selectAllProps = option.id === '-1' ? { checked: allSelected } : {};

        return (
          <StyledLi {...renderProps} key={option.id}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              checked={!!filteredData.find((item: ISelectOption) => item.id === option.id)}
              {...selectAllProps}
            />
            {option.label}
          </StyledLi>
        );
      }}
      renderInput={(params) => (
        <TextField {...params} placeholder={placeholder ?? 'Search'} InputLabelProps={{ shrink: true }} label={title} />
      )}
    />
  );
};

export default CustomAutocomplete;
