import React from 'react';
import { Box, Typography } from '@mui/material';
import Notifications from 'components/Notifications';
import { CookieManager } from 'helpers/CookieManager';

import { getFirstName, getInitials } from '../../../../../helpers/PersistManager/utils';

const ProfileBlock = () => {
  const userRoleName = CookieManager.getUser()?.roleName;
  const userName = CookieManager.getUser()?.name;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '8px 8px 8px 16px',
        margin: '8px 0',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '8px',
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            backgroundColor: '#9480FF',
            width: '40px',
            height: '40px',
            borderRadius: '8px',
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontWeight: '700',
              color: '#FFF',
              fontSize: '16px',
            }}
          >
            {userName && getInitials(userName)}
          </Typography>
        </Box>
        <Box>
          <Typography sx={{ color: '#454256', fontSize: '16px', fontWeight: 500 }} display="flex" gap="5px">
            Hello,
            <Typography
              component="span"
              sx={{
                fontSize: 'inherit',
                fontWeight: 'inherit',
                maxWidth: '50px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                display: 'inline-block',
                verticalAlign: 'middle',
                fontFamily: "'Inter',sans-serif",
              }}
            >
              {userName && getFirstName(userName)}
            </Typography>
          </Typography>
          <Typography sx={{ color: '#707D9D', fontSize: '12px', fontWeight: 500 }}>
            {userRoleName && userRoleName}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Notifications />
      </Box>
    </Box>
  );
};

export default ProfileBlock;
