import React, { useState } from 'react';
import { Box } from '@mui/material';
import HeaderWithFilters from 'components/HeaderWithFilters';

import { TableHeader } from '@types';

import FundingListFilters from '../../components/FundingList/FundingListFilters';
import FundingListTable from '../../components/FundingList/FundingListTable';
import { FUND_LIST_KEY, PersistManager } from '../../helpers/PersistManager';
import { isFilterOpen } from '../../helpers/PersistManager/utils';
import { FundingListHeaders } from '../../lib/constants/tableHeaders';

const FundingList = () => {
  const [selectedTableHeaders, setSelectedTableHeaders] = useState<TableHeader[]>(FundingListHeaders);
  const [page, setPage] = useState(0);
  const open = isFilterOpen(PersistManager.getData(FUND_LIST_KEY),["searchModel"]);



  return (
    <Box>
      <HeaderWithFilters title="Funding List" dataTest="funding-list-page-header" open={open}>
        <FundingListFilters
          setPage={setPage}
          selectedTableHeaders={selectedTableHeaders}
          setSelectedTableHeaders={setSelectedTableHeaders}
        />
      </HeaderWithFilters>
      <FundingListTable
        page={page}
        setPage={setPage}
        selectedTableHeaders={selectedTableHeaders}
        dataTest="funding-list-page"
      />
    </Box>
  );
};

export default FundingList;
