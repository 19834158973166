export const defaultFilterValues = (list: any[], ids: string[] = [], key: string = 'id') =>
  ids.reduce((newList, id) => {
    const newItem = list.find((item) => item[key] === id);

    if (newItem) {
      newList.push(newItem);
    }

    return newList;
  }, [] as any);

export const getInitials = (name: string) =>
  name
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase())
    .join('');

export const getFirstName = (name: string) => name.split(' ')[0];

export const getLastName = (name: string) => {
  const parts = name.split(' ');

  return parts[parts.length - 1];
};

export const isFilterOpen = <T extends Record<string, any>>(
  obj: T,
  excludeKeys: Array<keyof T> = [],
): boolean => {

  const { dateFrom, dateTo } = obj;


  if (dateFrom && dateTo) {
    const diffInYears = Math.abs(new Date(dateTo).getFullYear() - new Date(dateFrom).getFullYear());

    if (diffInYears !== 1) {
      return true;
    }
  }

  return Object.keys(obj).some((key) => {
    if (excludeKeys.includes(key as keyof T) || key === 'dateFrom' || key === 'dateTo') {
      return false;
    }

    const value = obj[key as keyof T];


    if (key === "status" && (value === 0 || value === null)) {
      return false;
    }

    if (value === 0) {
      return true;
    }

    if (Array.isArray(value)) {
      return value.length > 0;
    }

    if (typeof value === "string") {
      return value.trim().length > 0;
    }

    return value !== undefined && value !== null;
  });
};




