import { memo, useEffect, useMemo } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Box, Drawer } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import ProfileBlock from 'components/Layout/MainLayout/Sidebar/ProfileBlock';
import { drawerWidth } from 'lib/constants/grid';
import { menuMiddleware, menuSelector } from 'redux/slices/menu';

import { dispatch, useAppSelector } from '../../../../redux/hooks';
import ProfileSection from '../Header/ProfileSection';
import LogoSection from '../LogoSection';

import MenuList from './MenuList';

const StyledScrollbar = styled(PerfectScrollbar)({
  height: '100vh',
  paddingLeft: '16px',
  paddingRight: '16px',
});

const Sidebar = () => {
  const theme = useTheme();
  const drawerOpen = useAppSelector(menuSelector.drawerOpen);

  const { width } = window.screen;

  useEffect(() => {
    dispatch(menuMiddleware.setOpenDrawer(width > 900));
  }, [width]);

  const logo = useMemo(
    () => (
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <Box sx={{ display: 'flex', p: 2, mx: 'auto' }}>
          <LogoSection />
        </Box>
      </Box>
    ),
    [],
  );

  const drawer = useMemo(
    () => (
      <StyledScrollbar component="div">
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection="column"
          height="90%"
          alignContent="space-between"
        >
          <ProfileBlock/>
          <MenuList />
          <ProfileSection />
        </Box>
      </StyledScrollbar>
    ),
    [],
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { md: 0 },
        width: drawerWidth,
      }}
      aria-label="mailbox folders"
    >
      <Drawer
        variant="persistent"
        anchor="left"
        open={drawerOpen}
        onClose={() => dispatch(menuMiddleware.setOpenDrawer(!drawerOpen))}
        sx={{
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            background: theme.palette.background.default,
            color: 'rgba(69, 66, 86, 1)',
            borderRight: 'none',
            [theme.breakpoints.up('md')]: {
              top: '88px',
            },
          },
        }}
        ModalProps={{ keepMounted: true }}
        color="inherit"
      >
        {drawerOpen && logo}
        {drawerOpen && drawer}
      </Drawer>
    </Box>
  );
};

export default memo(Sidebar);
