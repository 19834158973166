import MenuIcon from '@mui/icons-material/Menu';
import { Box, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { dispatch, useAppSelector } from '../../../../redux/hooks';
import { menuMiddleware, menuSelector } from '../../../../redux/slices/menu';
// import Notifications from '../../../Notifications';
import LogoSection from '../LogoSection';

const Header = () => {
  const theme = useTheme();
  const drawerOpen = useAppSelector(menuSelector.drawerOpen);

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        [theme.breakpoints.down('md')]: {
          width: 'auto',
        },
      }}
    >
      <Box
        component="span"
        sx={{ width: '230px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
      >
        <LogoSection />
        <IconButton
          data-test="sidebar-button"
          sx={{
            width: '34px',
            height: '34px',
            transition: 'all 225ms',
            background: 'white',
            borderRadius: '8px',
            ':hover': {
              background: '#7B61FF',
              '& svg': {
                color: '#FFFFFF',
              },
            },
            border: `1px solid #f4f7fe`,
          }}
          onClick={() => dispatch(menuMiddleware.setOpenDrawer(!drawerOpen))}
          color="primary"
          aria-label="open sidebar"
        >
          <MenuIcon
            sx={{
              color: '#707D9D',
            }}
          />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Header;
