import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import HeaderWithFilters from 'components/HeaderWithFilters';
import { dispatch, useAppSelector } from 'redux/hooks';

import { useGenerateTableData } from '../../components/Hooks';
import { DenseTable } from '../../components/Tables';
import Loading from '../../components/UI/Loading';
import { APPLICATIONS_KEY, PersistManager } from '../../helpers/PersistManager';
import { isFilterOpen } from '../../helpers/PersistManager/utils';
import { DEFAULT_ROWS_PAGE } from '../../lib/constants';
import { Statuses } from '../../lib/constants/statuses';
import { LeadsTableHeaders } from '../../lib/constants/tableHeaders';
import { affiliatesMiddleware } from '../../redux/slices/affiliates';
import { campaignsMiddleware } from '../../redux/slices/campaigns';
import { leadsMiddleware, leadsSelector } from '../../redux/slices/leads';
import { manualReviewMiddleware } from '../../redux/slices/manualReviews';
import { tagsMiddleware, tagsSelector } from '../../redux/slices/tags';
import { ILeadsReqBody, TableHeader } from '../../types';

import { LeadsFilters } from './LeadsFilters';

const Leads = () => {
  const [selectedTableHeaders, setSelectedTableHeaders] = useState<TableHeader[]>(LeadsTableHeaders);

  const leads = useAppSelector(leadsSelector.leads);
  const isLeadsListLoading = useAppSelector(leadsSelector.isLeadsListLoading);
  const leadsCount = useAppSelector(leadsSelector.leadsCount);

  const tags = useAppSelector(tagsSelector.tags);

  const {
    tableData,
    setTableData,
    order,
    orderBy,
    page,
    setPage,
    rowsPerPage,
    handleRequestSort,
    createSortHandler,
    sortTableData,
    setOrder,
    setOrderBy,
    setRowsPerPage,
    paginationCount,
    handleSortCount,
  } = useGenerateTableData({
    dataIsAlreadyThere: leads,
    paginationCount: leadsCount,
  });

  const [updatedTableData, setUpdatedTableData] = useState([]);

  const handleChangePageServer = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    dispatch(
      leadsMiddleware.fetchLeadsList({
        searchModel: {
          pageNumber: newPage + 1,
          pageSize: rowsPerPage,
          sortOrder: { key: orderBy === 'borrower' ? 'firstName' : orderBy, value: order === 'asc' },
        },
      } as ILeadsReqBody),
    );
    setPage(newPage);
  };

  const handleChangeRowsPerPageServer = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | undefined,
  ) => {
    const newRowsPerPage = parseInt(event?.target.value!, 10);

    dispatch(
      leadsMiddleware.fetchLeadsList({
        searchModel: {
          pageNumber: 1,
          pageSize: newRowsPerPage,
          sortOrder: { key: orderBy === 'borrower' ? 'firstName' : orderBy, value: order === 'asc' },
        },
      } as ILeadsReqBody),
    );
    setPage(0);
    setRowsPerPage(newRowsPerPage);
  };

  const handleSortServer = (event: React.SyntheticEvent, property: string, newOrder: boolean) => {
    dispatch(
      leadsMiddleware.fetchLeadsList({
        searchModel: {
          pageNumber: page + 1,
          pageSize: rowsPerPage,
          sortOrder: { key: property === 'borrower' ? 'firstName' : property, value: newOrder },
        },
      } as ILeadsReqBody),
    );
  };

  useEffect(() => {
    setUpdatedTableData(sortTableData);
  }, [sortTableData]);

  useEffect(() => {
    dispatch(leadsMiddleware.fetchLeadsList());

    const orderData = PersistManager.getData(APPLICATIONS_KEY)?.searchModel;

    setOrder(orderData?.sortOrder?.value ? 'asc' : 'desc');
    setOrderBy(orderData?.sortOrder?.key ?? 'Id');
    setPage(Number(orderData?.pageNumber) - 1 ?? 0);
    setRowsPerPage(orderData?.pageSize ?? DEFAULT_ROWS_PAGE);

    dispatch(affiliatesMiddleware.fetchSimplifiedAffiliates());
    dispatch(affiliatesMiddleware.fetchSimplifiedAffiliateChannels());
    dispatch(campaignsMiddleware.fetchSimplifiedCampaigns());
    dispatch(manualReviewMiddleware.fetchMerchantNames());
    dispatch(tagsMiddleware.fetchTags());
  }, []);


  const open = isFilterOpen(PersistManager.getData(APPLICATIONS_KEY) || {},["searchModel"])

  return (
    <>
      <HeaderWithFilters title="Applications List" open={open}>
        <LeadsFilters
          setPage={setPage}
          selectedTableHeaders={selectedTableHeaders}
          setSelectedTableHeaders={setSelectedTableHeaders}
        />
      </HeaderWithFilters>
      <Box marginTop="24px">
        {isLeadsListLoading ? (
          <Loading />
        ) : (
          <Box>
            <Box
              sx={{
                '& div': {
                  width: 'max-content',
                },
                '& .MuiTablePagination-root': {
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                },
              }}
            >
              <DenseTable
                dataTest="leads-page"
                selectedTableHeaders={selectedTableHeaders}
                setOrder={setOrder}
                setOrderBy={setOrderBy}
                sortTableData={updatedTableData}
                data={tableData}
                isAction={false}
                action={{ type: 'Link' }}
                statusMode="BADGE"
                Statuses={Statuses}
                editButton={false}
                setTableData={setTableData}
                order={order as string}
                orderBy={orderBy}
                isMoreDropDown={false}
                page={page}
                isDeleted={false}
                rowsPerPage={rowsPerPage}
                handleRequestSort={handleRequestSort}
                createSortHandler={createSortHandler}
                handleChangeRowsPerPage={handleChangeRowsPerPageServer}
                handleChangePage={handleChangePageServer}
                handleSort={handleSortServer}
                paginationCount={paginationCount}
                tags={tags ?? []}
                tagsPage={1}
                handleSortCount={handleSortCount}
              />
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default Leads;
