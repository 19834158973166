import { UserRoles } from 'components/Permissions/constants';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import { ACCESS_TOKEN } from 'lib/constants';

const userId = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier';
const userName = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname';
const userEmail = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name';
const userRole = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';

interface TokenObject {
  [userId]: string;
  [userName]: string;
  [userEmail]: string;
  [userRole]: string;
  roleName: string;
  tenantGroupName: string;
  internalUser: string;
  tenantId: string;
  exp: number;
  aud: string;
  iss: string;
  nbf: number;
}

interface IUser {
  id: string;
  role: UserRoles;
  name: string;
  email: string;
  roleName: string;
}

export class CookieManager {
  static setAccessToken(token: string) {
    try {
      const decoded: TokenObject = jwtDecode(token);

      if (decoded?.exp) {
        this.setCookie(ACCESS_TOKEN, token, {
          secure: true,
          sameSite: 'Strict',
        });
      }
    } catch (error: any) {
      console.error(error.message);
    }
  }

  static getUser() {
    try {
      const decoded: TokenObject = jwtDecode(String(Cookies.get(ACCESS_TOKEN)));

      if (decoded[userId]) {
        const user: IUser = {
          id: decoded[userId],
          role: Number(decoded[userRole]),
          name: decoded[userName],
          email: decoded[userEmail],
          roleName: decoded.roleName,
        };

        return user;
      }

      return null;
    } catch (error: any) {
      console.error(error.message);

      return null;
    }
  }

  static setCookie(key: string, value: string, options = {}) {
    Cookies.set(key, value, options);
  }

  static removeCookie(key: string) {
    Cookies.remove(key);
  }

  static getCookie(key: string) {
    return Cookies.get(key);
  }
}
